export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBXPi7eoU_FVgTmt362NxzivtGJdq9p4cU",
    authDomain: "natrennt.firebaseapp.com",
    databaseURL: "https://natrennt-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "natrennt",
    storageBucket: "natrennt.appspot.com",
    messagingSenderId: "25082834324",
    appId: "1:25082834324:web:b3a499a808454345803381"
  }
};
