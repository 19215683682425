<div class="app-container" [ngClass]="pageLoaded ? 'visible' : ''">
  <div class="container" *ngIf="pageLoaded">

    <div class="championlist-container position-fixed">
      <button mat-fab aria-label="Zeige Highscore" class="championlist-button">
        <img src="./assets/images/trophy.svg" width="27px" (click)="toggleChampionlist()"/>
      </button>

      <div class="championlist-list-wrapper" *ngIf="!championListHidden">
        <div class="championlist mb-2" *ngFor="let champion of championlist;let i=index">
          <div class="p-1 d-flex flex-direction-coloumn align-items-center" >
            <div class="championlist__place mr-3">{{i+1}}</div>
            <div class="championlist__name">{{champion.vorname}} {{champion.name}}</div>
            <div class="championlist__km ml-auto mr-3">{{champion.km}}<span>km</span></div>
          </div>
        </div>
    </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <h1>#LaufmitNatalie in den Bundestag!</h1>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-6">
        <p>Zusammen mit Euch will ich in den kommenden Wochen mindestens 515 Kilometer, die Strecke von meiner Heimatstadt Bad Nauheim bis zum Bundestag nach Berlin laufen. Lasst uns die Kilometer zusammen sammeln. Egal ob laufen, walken oder einfach nur spazieren, jede und jeder kann mitmachen. </p>
        <p>Und so funktioniert es: Trage auf meiner Seite Deinen Namen, Wohnort und Deine gelaufene Strecke ein und schon werden die Kilometer im System gesammelt. Teile im Anschluss auch ein Foto von Dir bei dem Lauf/Walk/Spaziergang oder von Deiner Strecke in den sozialen Medien und verlinke mich und das Hashtag #LaufmitNatalie. Gerne kannst Du auch Deinen Familienmitgliedern, Freunden und Bekannten von der Aktion erzählen und sie motivieren mitzumachen.</p>        
         <p>Sportliche Grüße<br/>
          Deine Natalie
        </p>
      </div>
      <div class="col-md-3">
        <img src="./assets/natalie.jpg" class="natalie-img" alt="Natalie läuft"/>
      </div>
    </div>

    <div class="row">
      <div class="col text-center mb-4">
        <div class="progess-km-mask">
          <div class="progess-km-semi-circle"></div>
          <div class="progess-km-semi-circle--mask" [ngStyle]="{'transform': 'rotate('+ kmRotation +'deg)' }"></div>
        </div>
        <button id="add-card-button"
          mat-fab
          class="dialog-button"
          (click)="openDialog()"
          title="Lade deinen Lauf hoch">+
        </button>

        <div class="km-current position-relative">
          <mat-spinner [diameter]="40" *ngIf="!kilometer"></mat-spinner>
          <div class="km-current__now position-absolute">{{ kilometer | async}}km</div>  
          <div class="km-current__left position-absolute">{{ kilometerMax }}km</div>
          <div class="km-current__days mt-3">{{daysLeft}} Tage verbleiben</div>
        </div>

        <div class="d-flex flex-wrap">
          <img src="./assets/images/stempel-run.png" alt="Stempel bis nach Berlin gelaufen" class="stamp stamp-1"/>
          <img src="./assets/images/stempel-run2.png" alt="Stempel bis nach Berlin zum zweiten Mal gelaufen" class="stamp stamp-2"/>
          <img src="./assets/images/stempel-run3.png" alt="Stempel bis nach Berlin zum dritten Mal gelaufen" class="stamp stamp-3"/>
          <img src="./assets/images/stempel-run4.png" alt="Stempel bis nach Berlin zum vierten Mal gelaufen" class="stamp stamp-4"/>
          <img src="./assets/images/stempel-run5.png" alt="Stempel bis nach Berlin zum 5. Mal gelaufen" class="stamp stamp-5"/>
          <img src="./assets/images/stempel-run6.png" alt="Stempel bis nach Berlin zum 6. Mal gelaufen" class="stamp stamp-6"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 left-runs d-flex flex-wrap d-md-block" *ngIf="runsFirst">
        <ng-container *ngTemplateOutlet="runItem;context:{runsx: runsLeft}"></ng-container> 
      </div>

      <div class="col-md-6 text-center map-container p-0">
        <img src="./assets/deutschlandkarte-lauf.png"  class="map-image"/>
        <div class="map-progress" [style.width]="mapKmWidth"></div>
      </div>

      <div class="col-md-3 right-runs d-flex flex-wrap d-md-block" *ngIf="runsFirst">
        <ng-container *ngTemplateOutlet="runItem;context:{runsx: runsRight}"></ng-container> 
      </div>

      <div class="col-12 bottom-runs" *ngIf="runsFirst">
        <div class="d-flex flex-wrap mx-md-5 runs-container">
          <ng-container *ngTemplateOutlet="runItem;context:{runsx: runsBottom, margin: '30px'}"></ng-container> 
        </div>
      </div>

      <div class="col-12 text-center mt-4" *ngIf="showLoadMoreButton">
        <button id="load-more-runs-button"
        mat-raised-button	
        (click)="showMoreRuns()"
        title="Lade mehr">
        <div class="d-flex align-items-center">
          Mehr laden
          <mat-spinner [diameter]="20" class="ml-2" *ngIf="runsLoading"></mat-spinner>
        </div>
        </button>
      </div>
    </div>
  </div>
</div>

<footer class="app-container" [ngClass]="pageLoaded ? 'visible' : ''">
  <div class="social-media">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-column flex-lg-row">
            <h2 class="mb-3 mt-0">DU FINDEST MICH AUCH AUF</h2>

            <div class="d-flex flex-column">
              <a href="https://www.facebook.com/PawlikNatalie/" class="mb-3">
                <img src="./assets/images/facebook.svg" class="mr-3"/>
                <span>Facebook</span>
              </a>
              <a href="https://www.instagram.com/natalie.pawlik/?hl=de">
                <img src="./assets/images/instagram.svg"  class="mr-3" />
                <span>Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-links">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-wrap justify-content-between">
            <a href="https://natalie-pawlik.spd.de/footer-navigation/kontakt/"> Kontakt </a>
            <a href="https://www.spd.de/site/datenschutz/?pk_vid=a0556b3dd1f5890c1622708269d2c3e3"> Datenschutz </a>
            <a href="https://natalie-pawlik.spd.de/footer-navigation/impressum/"> Impressum </a>
            <a href="https://natalie-pawlik.spd.de/"> Über mich </a>
          </div> 
        </div>
      </div> 
    </div>
  </div>
</footer>

<mat-spinner [diameter]="50" class="page-loading-spinner" *ngIf="!pageLoaded"></mat-spinner>

<ng-template #runItem let-runsx="runsx" let-margin="margin">
  <div class="run-item py-2 py-md-3" *ngFor="let run of runsx" [ngStyle]="{'margin-top': margin}">
    <div class="run-item__name px-2 px-md-3 mb-2">
      {{ run.vorname }}
      {{ run.name }}
    </div>
    <mat-divider></mat-divider>
    <div class="run-item__km px-2 px-md-3">{{ run.km }}km</div>
    <div class="run-item__datum px-2  px-md-3">{{ run.datum}}, {{ run.ort }}</div>
  </div>
</ng-template>
