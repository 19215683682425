<div mat-dialog-content>
  <form [formGroup]="run">
    <div class="row mb-3">
      <div class="col-6">
        <mat-form-field class="w-100">
          <input
            matInput
            id="name"
            placeholder="Name"
            formControlName="name"
            maxlength="50" required/>
          <mat-error *ngIf="!run.controls.name.value &&
            run.controls.name.invalid &&
            run.controls.name.touched">Gib deinen Namen an</mat-error>
          <mat-error *ngIf="run.controls.name.value.length == 10">Maximal 50
            Zeichen zulässig</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <input
            matInput
            id="vorname"
            placeholder="Vorname"
            formControlName="vorname"
            maxlength="50" required/>
          <mat-error
            *ngIf="!run.controls.name.value &&
            run.controls.name.invalid &&
            run.controls.name.touched">Gib deinen Vornamen an</mat-error>
          <mat-error *ngIf="run.controls.name.value.length == 10">Maximal 50
            Zeichen zulässig</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Datum</mat-label>
          <input id="datum"
          formControlName="datum" matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [value]="maxDate" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>Datum nicht korrekt</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <input matInput
            id="kilometer"
            placeholder="Kilometer"
            type="number"
            max="42"
            min="1"
            formControlName="km"
            required />
          <mat-error *ngIf="!run.controls.km.value &&
            run.controls.km.invalid &&
            run.controls.km.touched">Gib deine KM-Anzahl an</mat-error>
          <mat-error *ngIf="(run.controls.km.invalid && run.controls.km.errors?.max)
            ||
            run.controls.km.errors?.min">Gib eine zulässige KM-Anzahl, max. 42km an</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <mat-form-field class="w-100">
          <input
            matInput
            id="ort"
            placeholder="Laufort"
            formControlName="ort"
            maxlength="100"/>
            </mat-form-field>
      </div>
    </div>

    <!-- <div class="row mb-3">
      <div class="col">
        <mat-form-field class="w-100">
          <input
            matInput
            id="email"
            placeholder="E-Mail"
            formControlName="email"
            maxlength="50"/>
            </mat-form-field>
      </div>
    </div> -->

    <!-- <div class="w-100 my-3">
      <input
        type="file"
        #pictureInput
        id="uploadFile"
        (change)="uploadImage($event)"
        name="uploadFile"
        multiple="false"
        accept="image/*"
        hidden
      />

      <button mat-raised-button (click)="pictureInput.click()" class="mr-3">
        Bild hochladen
      </button>
      {{ pictureName }}

      <mat-error
        class="mat-small"
        *ngIf="!pictureBasepath && run.controls.km.touched"
        >Lade ein Bild hoch</mat-error
      > 
    </div>-->
    <div class="row mb-3">
      <div class="col">
        <mat-checkbox formControlName="werbung" class="mb-3">Ich bin damit einverstanden, dass meine eingegebenen Daten angezeigt und zu diesem Zwecke gespeichert werden.*</mat-checkbox>
      </div>
    </div>
  </form>

 <p class="mat-small">* Pflichtfeld</p>
  <!-- <img *ngIf="pictureBasepath" [src]="pictureBasepath" width="200" /> -->
</div>

<div mat-dialog-actions class="d-flex justify-content-between mt-3">
  <button id="cancel-button" mat-raised-button (click)="onNoClick()">Abbrechen</button>
  <button id="save-button" mat-raised-button color="primary" (click)="saveRun()" cdkFocusInitial>
    Ok
  </button>
</div>
