<div mat-dialog-content>
  <h3>Vielen Dank für deinen Beitrag!</h3>
  <p>Wir prüfen und schalten ihn demnächst frei.</p>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>
    Schließen
  </button>
</mat-dialog-actions>
